import Alpine from 'alpinejs'
import ajax from '@imacrayon/alpine-ajax'
import * as Iconify from 'iconify-icon'

import '../css/style.scss'

import './ajax.js'

// import './elementor/widget-slider-top-product.js'
// import './elementor/widget-slider-autoplay.js'
// import './elementor/widget-slider-product-category.js'
// import './elementor/widget-home-slider-articles.js'
// import './elementor/widget-home-slider-athlete.js'
// import './elementor/widget-banner-slideshow.js'
// import './elementor/widget-slider-related-product.js'
// import './elementor/widget-slider-featured-articles.js'
// import './elementor/widget-slider-related-articles.js'
// import './elementor/widget-timeline.js'
// import './elementor/widget-slider-athlete.js'
// import './elementor/widget-map.js'

// Alpine
window.Alpine = Alpine
Alpine.plugin(ajax) // TODO: Add Alpine plugin is needed
Alpine.start()

// Iconify
window.Iconify = Iconify

// Swiper
// window.Swiper = Swiper;
// window.SwiperModules = {
//   HashNavigation,
//   Navigation,
//   Pagination,
//   Scrollbar,
//   Thumbs,
//   Autoplay,
//   Grid,
// };

// Instagram Slider
const instagramSlider = document.querySelectorAll('.slider__wrapper.instagram')
if (instagramSlider) {
    instagramSlider.forEach((ele) => {
        new Swiper(ele, {
            slidesPerView: 4,
            speed: 10000,
            // centeredSlides: true,
            autoplay: {
                delay: 0,
            },
            loop: true,
            allowTouchMove: false,
            disableOnInteraction: true,
            rewind: true,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                992: {
                    slidesPerView: 4,
                },
            },
        })
    })
}
