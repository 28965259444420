// console.log(ajax);

document.addEventListener('alpine:init', () => {
    Alpine.data('SliderTopProduct', () => ({
        // Init
        // Props
        active: 'Newest',
        isLoading: false,
        // Method
        async handleOnFilter() {
            const parentEle = this.$el.parentElement
            const id = parentEle.dataset.id

            // Change active button
            this.active = id

            try {
                this.isLoading = true

                const response = await fetch(ajax.url, {
                    method: 'POST',
                    headers: {
                        'Content-Type':
                            'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    body: new URLSearchParams({
                        action: 'slider_top_product_filter_ajax',
                        filter: id,
                        nonce: ajax.nonce,
                    }),
                })

                if (!response.ok) {
                    throw new Error('Cant connect')
                }

                const json = await response.json()
                const data = json.data

                // console.log(this.$refs.sliderList);
                const sliderListEle = this.$refs.sliderList
                sliderListEle.innerHTML = data.html
            } catch (error) {
                console.error('Error occured:', error.message)
            } finally {
                this.isLoading = false
            }
        },
    }))
    // Alpine.data("", () => ({
    //
    // }));
})
